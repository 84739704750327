import React, { useState } from 'react';
import { Turnstile } from '@marsidev/react-turnstile'

import './EmailSignupForm.css';


function EmailSignupForm(props) {
  const id = props.id;
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const turnstileRef = React.useRef()


  const replaceFormWithSuccessMessage = () => {
      const successMessage = document.createElement('p');
      successMessage.textContent = "A verification email was sent to complete your signup!";
      successMessage.classList.add('successful-signup');
      const formContainer = document.querySelector(`#signup-form-container-${id}`);
      formContainer.replaceChild(successMessage, formContainer.firstChild);
      turnstileRef.current?.remove();
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission

    if (isVerified) {
      try {
        const content = { "list_id": id, "email": email, "turnstiletoken": token };
        const response = await fetch("/signup", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(content),
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        // Replace the form with success message
        replaceFormWithSuccessMessage();
        setEmail(""); // Clear the email input after successful submission
      } catch (error) {
        console.error("Error submitting email signup:", error);
      }
    }
  };

  return (
    <div className="Email-Signup-div"  id={`signup-form-container-${id}`}>
      <form onSubmit={handleSubmit}>
        <label htmlFor="email">Email: </label>
        <input
          name="email"
          type="email"
          id="email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button className="EmailSignupForm-button" role="button" type="submit" disabled={!isVerified}>Sign Up</button>
        <Turnstile
          ref={turnstileRef}
          siteKey={process.env.REACT_APP_TURNSTILE_SITEKEY}
          onSuccess={
            (token) => {
              setToken(token);
              setIsVerified(true);
            }
          }
          scriptOptions={{
            defer: true,
            async: true,
          }}
        />
      </form>
    </div>
  );
}

export default EmailSignupForm;