import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import './Verify.css';


const Verify = () => {
  const [verificationStatus, setVerificationStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();


  useEffect(() => {
    const handleVerification = async () => {
      try {
        // Extract token from URL parameters
        const token = new URLSearchParams(window.location.search).get('id');

        if (!token) {
          setErrorMessage('Missing verification token!');
          throw new Error();
        }

        // Fetch data from server-side verification endpoint
        const response = await fetch(`/verify-backend?id=${token}`, {
          method: 'GET' // No need for Authorization header here
        });

        const data = await response.json();

        if (data.success) {
          setVerificationStatus(true);
        } else {
          setVerificationStatus(false);
          setErrorMessage(data.message);
        }
        setSearchParams({});
      } catch (error) {
        console.error('Verification error:', error);
        setVerificationStatus(false);
        setErrorMessage('Verification failed. Please try again.');
      }
    };

    handleVerification();
  }, []);

  return (
    <div className="Verify-div">
      {verificationStatus === null && <p>Verifying signup...</p>}
      {verificationStatus === true && <p>Signup successfully completed!</p>}
      {verificationStatus === false && <p>{errorMessage}</p>}
    </div>
  );
};

export default Verify;
