import React, { useState, useEffect } from 'react';
import ProgressBar from './ProgressBar';

function ParticipantProgress(props) {
  const {id, targetAmount} = props;
  const [count, setCount] = useState(0);
  const [mode, setMode] = useState(2);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const content = { "list_id": id };
        const response = await fetch('/participant-count', {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(content),
        });
        const data = await response.json();
        setCount(data.count);

        const response2 = await fetch('/progress-mode', {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(content),
        });
        const data2 = await response2.json();
        setMode(data2.mode);
      } catch (error) {
        console.error('Error fetching participant count:', error);
        setError('Error fetching count.');
      }
    };

    fetchData(); // Fetch data initially
    // Update every minute (adjust interval as needed)
    const intervalId = setInterval(fetchData, 1000);

    // Cleanup function to clear the interval
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array to run effect only once


  return (
    <ProgressBar bgcolor="rgb(100, 179, 60)" target={targetAmount} count={count} mode={mode} />
  );
}

export default ParticipantProgress;