import React, { useRef } from 'react';

const ProgressBar = (props) => {
  const { bgcolor, count, target, mode } = props;

  // mode: 0 => relative, 1 => absolute, 2 => none

  let completedPercentage;
  if (mode == 0 || mode == 1) {
    completedPercentage = count / target * 100;
    // always fill small fraction of progress to avoid large border radius differences
    completedPercentage = Math.max(completedPercentage, 3);
  } else {
    completedPercentage = 100;
  }

  const containerStyles = {
    height: 30,
    width: '100%',
    backgroundColor: "#cccccc",
    borderRadius: 50,
    position: 'relative',
  };

  const fillerStyles = {
    height: '100%',
    width: `${completedPercentage}%`,
    backgroundColor: bgcolor,
    borderRadius: 'inherit',
  };

  const labelStyles = {
    position: 'absolute', // Absolute position independent of filler div
    textAlign: 'center', // Center label horizontally
    top: '50%', // Center label vertically
    transform: 'translate(-50%,-50%)', // Adjust for half the font height/width
    color: 'black',
    fontWeight: 'bold',
  };

  let labelText;
  if (mode == 0) {
    labelText = `${completedPercentage.toFixed(2)}%`;
  } else if (mode == 1) {
    labelText = `${count} / ${target}`;
  } else {
    labelText = `${count}`;
  }

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}></div>
      <span style={labelStyles}>{labelText}</span>
    </div>
  );
};

export default ProgressBar;