import React, { useState, useEffect } from 'react';
import error404ContentPath from '../content/error404.txt';
import './NotFound.css';

function NotFound() {
    const [content, setContent] = useState('');
    useEffect(() => {
        fetch(error404ContentPath)
        .then(response => response.text())
        .then(data => setContent(data))
        .catch(error => console.error(error));
    }, []);
    return (
        <div className="NotFound-div">
            <pre>{content}</pre>
        </div>
    );
};

export default NotFound;