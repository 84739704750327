import React, { useState, useEffect } from 'react';
import Markdown from 'react-markdown';
import './Privacy.css';
import privacyContentPath from '../content/privacy.md';

function Privacy() {
    const [markdownContent, setMarkdownContent] = useState('');
    useEffect(() => {
        fetch(privacyContentPath)
        .then(response => response.text())
        .then(data => setMarkdownContent(data))
        .catch(error => console.error(error));
    }, []);
    return (
        <div className="Privacy-div">
            <Markdown>{markdownContent}</Markdown>
        </div>
    );
};

export default Privacy;