import React, { useState, useEffect } from 'react';
import Markdown from 'react-markdown';
import { useParams } from "react-router-dom"
import EmailSignupForm from './EmailSignupForm.js';
import ParticipantProgress from './ParticipantProgress.js';
import './List.css';
import './share-embed.js';
import '@justinribeiro/share-to-mastodon';

function getIFrameTagForList(list_id, list_title) {
    return `<iframe title="${list_title}" src="https://listofchange.org/embed/${list_id}" frameborder="0"></iframe>`
}

function getMailToLink(list_id, list_title, share_text) {
    const body = `${share_text} https://listofchange.org/list/${list_id}`;
    return `mailto:?subject=${list_title}&body=${body}`;
}

function ShareToEmail(props) {
  const { id, title, shareText } = props;
  return <a
  className="Share-button"
  href={getMailToLink(id, title, shareText)}
  target="_blank"
  >
    <span>Email</span>
  </a>
}

function List() {
  const { list_id } = useParams();

  const [listData, setListData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/list-data');
        const data = await response.json();
        console.log(data.listData);
        setListData(data.listData);
      } catch (error) {
        console.error('Error fetching list data:', error);
        setError('Error fetching list data.');
      }
    };

    fetchData();

  }, []); // Empty dependency array to run effect only once

  let shareEmbedProps;
  let shareMastodonProps;
  if (list_id && list_id >= 1 && list_id < listData.length + 1) {
    shareEmbedProps = {
      class: "Share-button",
      embedTag: getIFrameTagForList(list_id, listData[list_id - 1].title),
    };
    shareMastodonProps = {
      class: "Share-button",
      message: listData[list_id - 1].shareText,
      url: `https://listofchange.org/list/${list_id}`,
    };
  } else {
    shareEmbedProps = {
      class: "Share-button",
      embedTag: "",
    };
    shareMastodonProps = {
      class: "Share-button",
      message: "This initiative is amazing, come check it out!",
      url: "https://listofchange.org",
    };
  }

  return (
    (list_id && list_id >= 1 && list_id < listData.length + 1) ? (
        <div className="List-div">
        <h2>{listData[list_id - 1].title}</h2>
        <ParticipantProgress id={listData[list_id - 1].id} targetAmount={listData[list_id - 1].targetNumberOfParticipants} />
        <Markdown className="Markdown">{listData[list_id - 1].description}</Markdown>
        <EmailSignupForm id={listData[list_id - 1].id} />
        <div className='Share-div'>
          Share:
          <div className="Share-buttons-div">
            <ShareToEmail id={list_id} title={listData[list_id - 1].title} shareText={listData[list_id - 1].shareText}/>
            <share-to-mastodon {...shareMastodonProps}>Mastodon</share-to-mastodon>
            <share-embed {...shareEmbedProps}>Embed</share-embed>
          </div>
        </div>
        </div>
    ) : (
        <div className="List-div">
        <p>List ID is unknown.</p>
        </div>
    )
  );
}

export default List;