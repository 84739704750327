import React, { useState, useEffect } from 'react';
import Markdown from 'react-markdown';
import aboutContentPath from '../content/about.md';
import './About.css';

function About() {
    const [markdownContent, setMarkdownContent] = useState('');
    useEffect(() => {
        fetch(aboutContentPath)
        .then(response => response.text())
        .then(data => setMarkdownContent(data))
        .catch(error => console.error(error));
    }, []);
    return (
        <div className="About-div">
            <Markdown>{markdownContent}</Markdown>
        </div>
    );
};

export default About;