import {
  Link,
} from "react-router-dom";
import './Footer.css';

function Footer() {
    return <footer>
        <p>
          <Link className="FooterLink" to="about">About</Link> &ensp;|&ensp;
          <Link  className="FooterLink" to="legal">Legal</Link> &ensp;|&ensp;
          <Link  className="FooterLink" to="privacy">Privacy</Link>
        </p>
        <p><Link className="HomeLink" to="/">© 2024 List Of Change</Link></p>
      </footer>;
}

export default Footer;