import {
  Link,
} from "react-router-dom";
import ParticipantProgress from './ParticipantProgress.js';
import './ListPreview.css';

function ListPreview(props) {
  const {id, title, targetAmount} = props;

  return (
    <Link to={`/list/${id}`} className="ListPreview-Link">
      <div className="ListPreview-div">
        <h2 className="ListPreview-h2">{title}</h2>
        <ParticipantProgress id={id} targetAmount={targetAmount} />
      </div>
    </Link>
  );
}

export default ListPreview;