import React, { useState, useEffect } from 'react';
import {
  Link, useParams
} from "react-router-dom";
import ParticipantProgress from './ParticipantProgress.js';
import './ListEmbedding.css';

function ListEmbedding() {
  const { list_id } = useParams();

  const [listData, setListData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/list-data');
        const data = await response.json();
        setListData(data.listData);
      } catch (error) {
        console.error('Error fetching list data:', error);
        setError('Error fetching list data.');
      }
    };

    fetchData();

  }, []); // Empty dependency array to run effect only once

  return (
    (list_id && list_id >= 1 && list_id < listData.length + 1) ? (
      <Link to={`/list/${listData[list_id - 1].id}`} target="_blank" className="ListEmbedding-Link">
        <div className="ListEmbedding-div">
          <h2 className="ListEmbedding-h2">{listData[list_id - 1].title}</h2>
          <ParticipantProgress id={listData[list_id - 1].id} targetAmount={listData[list_id - 1].targetNumberOfParticipants} />
        </div>
      </Link>
    ) : (
        <div className="ListEmbedding-div">
        <p>List ID is unknown.</p>
        </div>
    )
  );
}

export default ListEmbedding;