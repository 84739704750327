import { html, css, LitElement } from 'lit';

/**
 * Adapted from: https://github.com/justinribeiro/share-to-mastodon
 * share-embed - a small web component that uses a internal mini-modal to
 * copy an embedding tag.
 *
 * @slot default - The text or what ever you want the link to be
 *
 * @cssprop [--wc-stm-color=black] - the host text color, default #000
 * @cssprop [--wc-stm-font-family=sans-serif] - the host font family, default sans-serif
 * @cssprop [--wc-stm-link-text-decoration=underline] - the link text decoration if no slot structures overwrite
 * @cssprop [--wc-stm-link-color-initial=blue] - the link text color if no slot structures overwrite
 * @cssprop [--wc-stm-link-color-active=red] - the link text color active
 * @cssprop [--wc-stm-link-color-visited=purple] - the link text color visited
 * @cssprop [--wc-stm-dialog-padding=1rem] - the mini-dialogs inner padding
 * @cssprop [--wc-stm-dialog-background-color=white] - the dialogs background color
 * @cssprop [--wc-stm-dialog-border-color=transparent] - the dialogs border color
 * @cssprop [--wc-stm-dialog-border-radius=0.5rem] - this dialogs border radius
 * @cssprop [--wc-stm-title-margin-top-bottom=0.5rem] - the mini-dialogs title margin for the H2
 * @cssprop [--wc-stm-form-input-padding=0.5rem]
 * @cssprop [--wc-stm-form-input-border-radius=0.25rem]
 * @cssprop [--wc-stm-form-input-border=1px solid #ccc]
 * @cssprop [--wc-stm-form-input-font-size=1em]
 * @cssprop [--wc-stm-form-button-border]
 * @cssprop [--wc-stm-form-button-border-radius=0.25rem]
 * @cssprop [--wc-stm-form-button-background-color=#eee]
 * @cssprop [--wc-stm-form-button-padding=0.5rem 0]
 * @cssprop [--wc-stm-form-button-font-size=1em]
 * @cssprop [--wc-stm-form-button-color=inherit]
 * @cssprop [--wc-stm-form-submit-background-color=#ccc]
 * @cssprop [--wc-stm-form-cancel-background-color=#eee]
 * @cssprop [--wc-stm-form-submit-color=inherit]
 * @cssprop [--wc-stm-form-cancel-color=inherit]
 *
 * @extends {LitElement}
 */
export class ShareEmbed extends LitElement {
  static get styles() {
    return css`
      :host {
        will-change: transform, opacity;
        color: var(--wc-stm-color, #000);
        font-family: var(--wc-stm-font-family, sans-serif);
      }

      a {
        text-decoration: var(--wc-stm-link-text-decoration, underline);
        color: var(--wc-stm-link-color-initial, blue);
      }
      a:active {
        color: var(--wc-stm-link-color-active, red);
      }
      a:visited {
        color: var(--wc-stm-link-color-visited, purple);
      }

      #title {
        margin: var(--wc-stm-title-margin-top-bottom, 0.5rem) 0;
      }

      dialog {
        background-color: var(--wc-stm-dialog-background-color, #fff);
        border-radius: var(--wc-stm-dialog-border-radius, 0.5rem);
        border-color: var(--wc-stm-dialog-border-color, transparent);
      }

      form {
        display: flex;
        flex-direction: column;
      }

      form > p,
      form > div {
        margin: 0;
        padding: var(--wc-stm-form-input-padding, 1rem 0);
      }

      #actions {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
      }

      #actions > button {
        border: var(--wc-stm-form-button-border);
        border-radius: var(--wc-stm-form-button-border-radius, 0.25rem);
        padding: var(--wc-stm-form-button-padding, 0.5rem 0);
        font-size: var(--wc-stm-form-button-font-size, 1em);
        width: calc(50% - 0.5rem);
      }

      #actions > button:hover {
        border: var(--wc-stm-form-button-border);
        border-radius: var(--wc-stm-form-button-border-radius, 0.25rem);
        background-color: var(
          --wc-stm-form-button-background-color-hover,
          #ccc
        );
        color: var(--wc-stm-form-button-color-hover, inherit);
      }

      #submitButton {
        background-color: var(--wc-stm-form-submit-background-color, #8686fd);
        color: var(--wc-stm-submit-button-color, inherit);
      }

      #closeButton {
        background-color: var(--wc-stm-form-cancel-background-color, #eeeeee);
        color: var(--wc-stm-cancel-button-color, inherit);
      }
    `;
  }

  static get properties() {
    return {
      /**
       * The dialog modal title. Also used for screen readers.
       * @attr
       */
      modalTitle: { type: String },
      /**
       * The embedTag you'd like to copy.
       * @attr
       */
      embedTag: { type: String },
      /**
       * The string that is displayed above the textarea field on the mini-modal
       * @attr
       */
      modalMessage: { type: String },
      /**
       * The string that is displayed on the action button to share.
       * @attr
       */
      modalCopyButton: { type: String },
      /**
       * The string that is displayed on the action button to cancel the dialog.
       */
      modalCancel: { type: String },
    };
  }

  constructor() {
    super();
    this.message = '';
    this.modalTitle = 'Share by embedding';
    this.modalMessage = "Copy embedding tag and insert into your website.";
    this.modalCopyButton = 'Copy';
    this.modalCancel = 'Close';
  }

  firstUpdated() {
    const form = this.shadowRoot.querySelector('form');
    form.addEventListener('submit', this.__formSubmitHandler.bind(this));
  }

  disconnectedCallback() {
    const form = this.shadowRoot.querySelector('form');
    form.removeEventListener('submit', this.__formSubmitHandler.bind(this));
  }

  /**
   * Handle the form submit and copy the embed tag.
   * @param {Event} event
   */
  __formSubmitHandler(event) {
    event.preventDefault();
    navigator.clipboard.writeText(this.embedTag);
    this.__close();
  }

  /**
   * This opens the modal.
   * @param {Event} event
   */
  __openModal(event) {
    event.preventDefault();

    this.shadowRoot.querySelector('dialog').showModal();
  }

  __close() {
    this.shadowRoot.querySelector('dialog').close();
  }

  render() {
    return html`
      <dialog aria-labelledby="title">
        <form>
          <h2 id="title">${this.modalTitle}</h2>
          <label for="embedtag">${this.modalMessage}</label>
          <textarea
            type="embedtag"
            name="embedtag"
            id="embedtag"
            pattern="<iframe*>"
            readonly
            rows="6"
            cols="30"
            .value="${this.embedTag}"
          ></textarea>
          <div id="actions">
            <button id="submitButton">${this.modalCopyButton}</button>
            <button type="button" id="closeButton" @click="${this.__close}">
              ${this.modalCancel}
            </button>
          </div>
        </form>
      </dialog>
      <a
        @click=${this.__openModal}
      >
        <slot></slot>
      </a>
    `;
  }
}

window.customElements.define('share-embed', ShareEmbed);
