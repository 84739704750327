import React, { useState, useEffect } from 'react';
import ListPreview from './ListPreview.js';
import './Home.css';

function Home() {
  const [listData, setListData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/list-data');
        const data = await response.json();
        setListData(data.listData);
      } catch (error) {
        console.error('Error fetching list data:', error);
        setError('Error fetching list data.');
      }
    };

    fetchData();

  }, []); // Empty dependency array to run effect only once

  return (
    <div className="Home-div">
      {(listData.map((item) => (
        <ListPreview key={item.id} id={item.id} title={item.title} targetAmount={item.targetNumberOfParticipants}/>
        ))
      )}
    </div>
  );
}

export default Home;