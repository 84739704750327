import React, {useEffect} from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";

import About from './components/About.js';
import LegalNotice from './components/LegalNotice.js';
import Privacy from './components/Privacy.js';
import Verify from './components/Verify.js';
import Unsubscribe from './components/Unsubscribe.js';
import NotFound from './components/NotFound.js';
import Layout from './components/Layout.js';
import Home from './components/Home.js';
import List from './components/List.js';
import ListEmbedding from './components/ListEmbedding.js';


const RedirectToNotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/404', { replace: true });
  }, [navigate]);

  return (
    <div>Redirecting...</div>
  );
};


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Home/>}/>
          <Route path="about" element={<About/>}/>
          <Route path="legal" element={<LegalNotice/>}/>
          <Route path="privacy" element={<Privacy/>}/>
          <Route path="verify" element={<Verify/>}/>
          <Route path="unsubscribe" element={<Unsubscribe/>}/>
          <Route path="/404" element={<NotFound/>}/>
          <Route path="/list/:list_id" element={<List/>} />
          <Route path="*" element={<RedirectToNotFound/>}/>
        </Route>
        <Route path="/embed/:list_id" element={<ListEmbedding/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
