import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import './Unsubscribe.css';


const Unsubscribe = () => {
  const [unsubscribedStatus, setUnsubscribedStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();


  useEffect(() => {
    const handleUnsubscribing = async () => {
      try {
        // Extract token from URL parameters
        const token = new URLSearchParams(window.location.search).get('id');

        if (!token) {
          setErrorMessage('Missing unsubscribe token!');
          throw new Error();
        }

        // Fetch data from server-side verification endpoint
        const response = await fetch(`/unsubscribe-backend?id=${token}`, {
          method: 'GET' // No need for Authorization header here
        });

        const data = await response.json();

        if (data.success) {
          setUnsubscribedStatus(true);
        } else {
          setUnsubscribedStatus(false);
          setErrorMessage(data.message);
        }
        setSearchParams({});
      } catch (error) {
        console.error('Unsubscribe error:', error);
        setUnsubscribedStatus(false);
        setErrorMessage('Unsubscribing failed. Please try again.');
      }
    };

    handleUnsubscribing();
  }, []);

  return (
    <div className="Unsubscribe-div">
      {unsubscribedStatus === null && <p>Unsubscribing...</p>}
      {unsubscribedStatus === true && <p>Successfully unsubscribed!</p>}
      {unsubscribedStatus === false && <p>{errorMessage}</p>}
    </div>
  );
};

export default Unsubscribe;
